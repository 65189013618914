<template>
    <div class="page page-404">
        <div class="page-404-block">
            <div class="page-404-col page-404-left img-brush">
                <img :src='this.$pathprod+"/images/global/brush.svg"' alt="trace-de-peinture">
            </div>
            <div class="page-404-col page-404-right">
                <div class="page-404-content">
                    <p class="text-404">404</p>
                    <p class="desc-404">Il semblerait que la page demandée n’existe pas</p>
                    <router-link :to="{name:'Home', params: { lang: this.$i18n.locale }}" class="btn btn-back">ACCUEIl</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageNotFound',
    props: {
        msg: String,
    },

    metaInfo() {
        return { 
            title: "ERREUR 404",
            meta: [
                { name: 'description', content:  'Page introuvable. ERREUR 404'},
                { property: 'og:title', content: "ERREUR 404"},
                { property: 'og:site_name', content: 'Rhapsody'},
                { property: 'og:type', content: 'website'},    
            ]
        }
    },
}
</script>
<style scoped>

</style>